import { CustomBootstrapTable, CustomButton, CustomModal } from "components/Common"
import {
  getAllOrganizations, getAllTestProfiles
} from "helpers/app-backend"
import { useEffect, useState } from "react"
import { ModalBody, ModalFooter } from "reactstrap"

//i18n
import { AvForm } from "availity-reactstrap-validation"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { deleteProfileVendor, updateProfileVendor } from "store/actions"
import FilterVendorForm from "./FilterVendorForm"
import PartnerSend from "./PartnerSend"

const RESOURCE = ModuleIds.Test

const AddProfileSend = ({
  modal,
  toggle,
  data = [],
  onUpdateProfileVendor,
  onDeleteProfileVendor,
  t,
}) => {
  const title = t("Add profile send")
  const [profiles, setProfiles] = useState([])
  const [model, setModel] = useState({})
  const [organizations, setOrganizations] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchProfile()
  }, [model])

  useEffect(() => {
    setProfiles([])
    fetchProfile()
    setModel({})
  }, [modal])

  useEffect(() => {
    if (organizations.length === 0) {
      getOrganizations()
    }
  }, [])

  const fetchProfileCode = async (searchQuery) => {
    let dataProfile = await getAllTestProfiles(searchQuery)
    const data = dataProfile?.data.filter(item => item.type !== 2)
    return data
  }

  const fetchProfile = async () => {
    setLoading(true)
    const query = {
      ...model,
      vendorCode: true,
      inUse: true,
      size: 0,
    }
    if (Array.isArray(data)) {
      const newProfile = data?.filter(item => !item.vendorCode) || []
      newProfile.forEach(item => {
        item.isChange = true
        item.newProfile = true
      })

      const res = await fetchProfileCode(query)

      if (organizations.length > 0) {
        for (const item of res) {
          const result = organizations?.find(x => x.organizationCode == item.vendorCode)
          item.name = result?.name || ''
        }
      }
      setProfiles([...newProfile, ...res])
      setLoading(false)
    }
  }

  const getOrganizations = async () => {
    const res = await getAllOrganizations({ page: 1, size: 0 })
    setOrganizations(res.data)
  }

  const onValidSubmit = async () => {
    // let result = profiles.filter(item => !item.isDelete)
    // result = result.map(x => {
    //   if (x.profileId == undefined || x.profileId == null) {
    //     x.profileId = x.id
    //   }
    //   return x;
    // })
    const result = profiles.filter(item => item.isChange
      // && item.vendorCode !== null 
      && !item.isDelete)
    const profileRemove = profiles.filter(item => item.isDelete)
    if (profileRemove.length > 0) {
      const ids = profileRemove.map(item => item.id)
      onDeleteProfileVendor(ids, () => {
        onUpdateProfileVendor({ vendorList: result.map(x => ({ ...x, profileId: x.profileId || x.id })) }, () => {
          onResetHandler()
        })
      })
    } else {
      onUpdateProfileVendor({ vendorList: result.map(x => ({ ...x, profileId: x.profileId || x.id })) }, () => {
        onResetHandler()
      })
    }
  }

  const onSearch = searchText => {
    setModel({ ...model, search: searchText })
  }

  const onSubmitFilter = (e, values) => {
    setModel({ ...model, ...e })
  }

  const onResetHandler = () => {
    const initModel = {
      vendorCode: true,
      inUse: true,
      size: 0,
    }
    setModel(initModel)
  }

  const columns = [
    {
      dataField: "profileCode",
      text: t("testProfilePage:Code"),
      style: { width: '100px' },
      searchable: true,
      formatter: (cellContent, item, index) => {
        return <span style={{ color: item.newProfile && '#99caff' }} >{cellContent ?? item.code}</span>
      },
    },
    {
      dataField: "profileName",
      text: t("Profile"),
      searchable: true,
      formatter: (cellContent, item, index) => {
        return <span style={{ color: item.newProfile && '#99caff' }} >{cellContent}</span>
      },
    },
    {
      dataField: "categoryName",
      text: t("testPage:Category"),
      searchable: true,
      formatter: (cellContent, item, index) => {
        return <span style={{ color: item.newProfile && '#99caff' }} >{cellContent}</span>
      },
    },
    {
      dataField: "partnerSend",
      text: t("testPage:Partner Send"),
      style: { width: '200px' },
      formatter: (cellContent, item, index) => {
        return <PartnerSend
          partnerSend={{ vendorCode: item.vendorCode, name: item.name }}
          onPartnerChange={(vendor) => {
            profiles[index].isChange = true
            profiles[index].vendorCode = vendor?.organizationCode
            profiles[index].profileId = item.id
            setProfiles([...profiles])
          }} />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, item, index) => {
        return <AvForm>
          <FormSwitchForm
            name="vendorCode"
            label=''
            value={true}
            onVendorChange={(value) => {
              profiles[index].isDelete = !value
              profiles[index].isChange = true
              setProfiles([...profiles])
            }}
          />
        </AvForm>
      },
    },
  ]
  return (
    <CustomModal modal={modal} title={title} size="xl" onToggle={toggle}>
      <ModalBody style={{ height: 900 }}>
        <CustomBootstrapTable
          columns={columns}
          data={profiles}
          search
          showSelectRow={false}
          onSearch={onSearch}
          searchText={model.search}
          filterForm={() => <FilterVendorForm model={model} />}
          onSubmitFilter={onSubmitFilter}
          resource={RESOURCE}
          onReset={onResetHandler}
          onRefresh={() => {
            fetchProfile()
          }}
          isEnableExport={false}
          loading={loading}
          paging={{ dataSize: profiles.length, size: profiles.length }}
        />
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={onValidSubmit}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

const mapStateToProps = ({ profile }) => ({
})

const mapDispatchToProps = dispatch => ({
  onUpdateProfileVendor: (profile, callback) => dispatch(updateProfileVendor(profile, callback)),
  onDeleteProfileVendor: (profile, callback) => dispatch(deleteProfileVendor(profile, callback))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["profilePage", "common", "testPage", "testProfilePage"])(AddProfileSend))
