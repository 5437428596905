import { CustomButton, CustomSID, FilterButton } from "components/Common";
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync";
import { ModuleIds } from "constant";
import { convertDateFormat, convertDateFormat_VN, indexCalculator } from "helpers/utilities";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import FilterForm from "./FilterForm";
import { useEffect, useState } from "react";

const RESOURCE = ModuleIds.PatientVisit;

const WatingPatient = ({
  onSelectPatient,
  data,
  t,
  pagingWaiting,
  onResetHandler,
  onSubmitFilterPatient,
  patientDetail,
  model
}) => {
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    if (loading !== null) {
      setLoading(!loading)
    }
  }, [data])

  let countTotal = 0
  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (cellContent, item, index) => {
        if (item.id == -1) {
          return <span id={`watch_end_of_document1`}></span>
        }
        else
          return indexCalculator(0, 0, index + 1)
      },
    },
    {
      dataField: "requestDate",
      text: t("patientVisitPage:Date"),
      formatter: (cellContent, item, index) => {
        return convertDateFormat(item.createdDate, "DD-MM-YYYY")
      },
    },
    {
      dataField: "sid",
      text: "SID",
      formatter: (cellContent, item, index) => {
        const data = item?.sampleIds ? JSON.parse(item?.sampleIds) : []
        const removeDuplicate = data.filter(
          (element, index) => index === data.findIndex(
            other => element.SID === other.SID
          ))
        return item.sampleIds &&
          removeDuplicate.map((x, postion) =>
            <CustomSID
              key={`${index}-${postion}`}
              keyToolTip={`${index}-${postion}`}
              isToolTip={true}
              toolTip={x?.SampleTypeName}
              sid={x?.SID}
              emergency={item?.emergency}
            />
          )
      },
    },
    {
      dataField: "",
      text: "",
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex">
            {item?.groupCode && <i
              key={index}
              data-tip
              data-for={`groupCode-${index}`}
              className="mdi mdi-bookmark"
              style={{
                fontSize: 'larger',
                color: 'purple'
              }}
            >
              {item.organizationName && <ReactTooltip id={`groupCode-${index}`} place="top" effect="solid">
                {item.organizationName || ''}
              </ReactTooltip>}
            </i>}
          </div>
        )
      }
    },
    {
      dataField: "patientName",
      text: t("Name"),
      formatter: (cellContent, item, index) => {
        return (
          <div style={{ color: "#3c3ce4", paddingTop: '2px' }}>{item.patientName}</div>
        )
      }
    },
    {
      dataField: "age",
      text: t("DOB"),
      formatter: (cellContent, item, index) => (
        item.dob && <div>{convertDateFormat_VN(item.dob, "DD-MM-YYYY")}</div>
      ),
    },
  ]

  const getDataDisplay = () => {
    let res = [...data]
    if (res.length > 0) {
      res.push({ id: -1, testCode: '' })
    }
    countTotal = res.length
    return res
  }

  const rowClasses = (row, rowIndex) => {
    return row.id === patientDetail?.id ? "patient-select" : ""
  }

  return (
    <div className={`table-patient-visit ${data.length > 0 ? 'table-expand-custom table-rule-management' : ''}  `} style={{ height: '100%' }}>
      <CustomBootstrapTableAsync
        TableTitle={t('Patient List')}
        columns={columns}
        data={getDataDisplay()}
        keyField="id"
        onRowClick={onSelectPatient}
        showSelectRow={false}
        paging={pagingWaiting}
        isScrollable={true}
        rowClasses={rowClasses}
        resource={RESOURCE}
        style={{ height: '260px' }}
        loading={loading}
        onRefresh={() => {
          onResetHandler('2')
          setLoading(true)
        }}
        customButtonRight={() => (
          <FilterButton
            onReset={onResetHandler}
            onSubmit={onSubmitFilterPatient}
            direction={'end'}
            className={''}
          >
            <FilterForm model={model} />
          </FilterButton>
        )}
      />
    </div>
  )
}
export default withTranslation(["testRequestPage", "message", "patientVisitPage"])(WatingPatient)
