import { AvForm } from "availity-reactstrap-validation"
import { CustomAutoComplete } from "components/Common"
import { TestRequest_Test_Type } from "constant"
import PropTypes from "prop-types"
import { useState } from "react"

const CustomSelectField = ({
    fieldName,
    item,
    code,
    onEditableChange,
    value,
    autoFocus
}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div style={{ width: '100%' }}>
            {!isOpen && <div className="inline-field" onClick={() => {
                setIsOpen(true)
            }}>
                {value || "---"}
            </div>}
            {isOpen && <div onBlur={() => { setIsOpen(false) }}>
                <AvForm>
                    <CustomAutoComplete
                        name={fieldName}
                        label={""}
                        autoFocus={autoFocus}
                        disabled
                        value={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item[fieldName] == null || item[fieldName] == '') ? [] : item[fieldName]}` : []}
                        code={code}
                        onChange={(a, value, itemSelect) => {
                            onEditableChange(value[0], item, fieldName, true, itemSelect)
                            setIsOpen(false)
                        }}
                    />
                </AvForm>
            </div>}
        </div>
    )
}

CustomSelectField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    fieldName: PropTypes.any,
    code: PropTypes.any,
    onEditableChange: PropTypes.any,
}

export default CustomSelectField